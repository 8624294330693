<script>
  import { _ } from 'svelte-i18n';
  import { convertHexToRGBA, RGBAToHexA } from '../../utils/colors';
  const DEFAULT_BG_COLOR = 'transparent';

  import MediaQuery from '../../components/MediaQuery.svelte';
  import CallToActionPartial from './CallToActionPartial.svelte';

  export let primary;
  export let items;

  const {
    fill_color_percentage: fillColorPercentage,
    content_offset: contentOffset,
    background_color: backgroundColor,
    background_image: backgroundImage,
    button_text,
    button_link,
    heading,
    content_padding_top: contentPaddingTop,
    content_padding_bottom: contentPaddingBottom,
    button_placement: buttonPlacement,
  } = primary;
  const features = items;
  const button = {
    text: button_text,
    link: button_link,
  };

  const _backgroundColor = backgroundColor || DEFAULT_BG_COLOR;

  let style = '';
  let hexaColor = '00FFFFFF';
  if (_backgroundColor && _backgroundColor !== DEFAULT_BG_COLOR) {
    style = `--bgColor: ${convertHexToRGBA(
      _backgroundColor,
      fillColorPercentage
    )};`;
    hexaColor =
      _backgroundColor && _backgroundColor !== DEFAULT_BG_COLOR
        ? RGBAToHexA(
            convertHexToRGBA(_backgroundColor, fillColorPercentage)
          ).replace('#', '')
        : '00FFFFFF';
  }

  if (contentPaddingTop) {
    style = style.concat(`padding-top: ${contentPaddingTop}px;`);
  }

  if (contentPaddingBottom) {
    style = style.concat(`padding-bottom: ${contentPaddingBottom}px;`);
  }

  if (backgroundImage && backgroundImage.url) {
    style = style.concat(
      backgroundImage
        ? `--bgImage: url(${backgroundImage.url}&blend-mode=normal&blend=${hexaColor});`
        : ''
    );
  }

  if (fillColorPercentage) {
    style = style.concat(
      fillColorPercentage ? `--bgOpacity: ${fillColorPercentage};` : ''
    );
  }
</script>

<MediaQuery
  query="(-webkit-min-device-pixel-ratio: 3), (min-resolution: 576dpi)"
  let:matches
>
  {#if matches && backgroundImage && backgroundImage.url}
    <CallToActionPartial
      backgroundImage={`--bgImage: url(${backgroundImage.url}&blend-mode=normal&blend=${hexaColor})`}
      {button}
      {buttonPlacement}
      {contentOffset}
      {features}
      {heading}
      {primary}
      {style}
    />
  {:else}
    <MediaQuery query="(min-width: 1200px)" let:matches>
      {#if matches && backgroundImage && backgroundImage.xl && backgroundImage.xl.url }
        <CallToActionPartial
          backgroundImage={`--bgImage: url(${backgroundImage.xl.url}&blend-mode=normal&blend=${hexaColor})`}
          {button}
          {buttonPlacement}
          {contentOffset}
          {features}
          {heading}
          {primary}
          {style}
        />
      {:else}
        <MediaQuery query="(min-width: 600px)" let:matches>
          {#if matches && backgroundImage && backgroundImage.lg && backgroundImage.lg.url}
            <CallToActionPartial
              backgroundImage={`--bgImage: url(${backgroundImage.lg.url}&blend-mode=normal&blend=${hexaColor})`}
              {button}
              {buttonPlacement}
              {contentOffset}
              {features}
              {heading}
              {primary}
              {style}
            />
          {:else}
            {#if backgroundImage && backgroundImage.md && backgroundImage.md.url }
                <CallToActionPartial
                  backgroundImage={`--bgImage: url(${backgroundImage.md.url}&blend-mode=normal&blend=${hexaColor})`}
                  {button}
                  {buttonPlacement}
                  {contentOffset}
                  {features}
                  {heading}
                  {primary}
                  {style}
                />
              {/if}
          {/if}
        </MediaQuery>
      {/if}
    </MediaQuery>
  {/if}
</MediaQuery>
