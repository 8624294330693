<script>
  import { _ } from 'svelte-i18n';
  import { LANGUAGE } from '../constants';
</script>

<style>
  .mobile-apps {
    width: 100%;
    margin: auto;
    background-image: url('/mobile-app-banner-bg.jpg');
    background-size: cover;
    background-position: center;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 14px;
  }
  .title {
    margin-bottom: 30px;
  }
  .description {
    margin-bottom: 48px;
  }
  .body {
    margin-bottom: 60px;
  }

  .phone-container {
    height: 425px;
    margin: 60px 100px;
  }

  .phone,
  .phone video {
    height: 100%;
  }

  .phone {
    position: relative;
  }
  .phone video {
    display: block;
    border: 0.4em solid #fff;
    border-radius: 1.4em;
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  }

  .button-wrapper {
    margin-right: 10px;
    display: inline-block;
  }
  .button {
    height: 45px;
  }
</style>

<div class="mobile-apps">
  <div class="phone-container">
    <div class="phone">
      <video autoplay loop muted playsinline class="phone-content">
        <source
          src="screenshots/{LANGUAGE}/mobile-app.h264.mp4"
          type="video/mp4" />
      </video>
    </div>
  </div>
  <div class="body">
    <h3 class="title">{$_('downloadOurApps')}</h3>
    <div class="description">{$_('bothForYouAsCustomerAndAdministrator')}</div>
    <a
      target="_blank"
      class="button-wrapper"
      href="https://play.google.com/store/apps/details?id=com.oneclick.bokameraadmin&hl=sv"
      rel="noopener">
      <img src="/download-google-play.svg" alt="Google Play" class="button" />
    </a>
    <a
      target="_blank"
      class="button-wrapper"
      href="https://itunes.apple.com/se/app/bokamera-admin/id797073515?mt=8"
      rel="noopener">
      <img src="/download-app-store.svg" alt="App Store" class="button" />
    </a>
  </div>
</div>
