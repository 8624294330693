<script>
  import Ribbon from './Ribbon.svelte';
  import PlanFeature from './PlanFeature.svelte';
  import { _ } from 'svelte-i18n';

  export let primary = false;
  export let floating = false;
  export let type;
  export let title;
  export let price;
  export let label;
  export let currency = $_('pricingPlans.currency');
  export let period = $_('pricingPlans.period');
  export let features = [];
  export let hideHeader = false;

  const ENTERPRISE_TYPE_ID = 9;
</script>

<div class="plan-col">
  {#if !hideHeader}
    <div class="header">
      {#if label}
        <Ribbon right>{label}</Ribbon>
      {/if}
      <div class="title">
        <h2>{title}</h2>
      </div>
      {#if type.Prices[0]}
        <div class="price">
          <span class="size-2">
            {type.Prices[0].Country.CurrencyId}
          </span>
          <span class="size-3">
            {type.Prices[0].Price}
          </span>
          <span class="size-1">
            /{type.Prices[0].MonthlyPayment ? $_('pricingPlans.period') : ''}
          </span>
        </div>
      {/if}
    </div>
  {/if}

  {#each features as feature,featureIndex}
    <PlanFeature {feature} />
  {/each}
</div>

<style>

  .header {
    background-color: #f0f7fe;
    padding: 40px 5px 0px 5px;
    position: relative;
  }
  .plan-col {
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 2px 0px rgba(76, 75, 76, 0.18);
    flex-wrap: nowrap;
    min-width: calc((100% - 298px)/5);
    max-width: 380px;
  }

  .title h2 {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
    margin-bottom: 20px;
  }

  .price {
    text-transform: uppercase;
    color: #ee7129;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1.56;
    margin-bottom: 20px;
    align-items: baseline;
    white-space: nowrap;
  }

  .size-1 {
    font-weight: lighter;
    font-size: 12px;
  }

  .size-2 {
    font-weight: lighter;
    font-size: 14px;
  }

  .size-3 {
    font-weight: bold;
    font-size: 20px;
  }

  @media only screen and (min-width: 1400px) {
    .plan-col {
      /* margin-right: 20px; */
    }
    .header {
      padding: 69px 30px 0px 30px;
    }
  }

  @media only screen and (max-width: 60em) {
    .plan-col {
      margin-bottom: 20px;
    }
  }


</style>
