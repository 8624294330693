<script>
  import MediaQuery from './MediaQuery.svelte';
  export let title;
  export let categories = [];
  export let imgSrc;
  export let link;
  export let resposiveImages;

  import { _ } from 'svelte-i18n';
</script>

<style>
  .category-group {
    width: 256px;
    box-shadow: 0.5px 0.9px 37.2px 0.8px rgba(40, 39, 39, 0.16);
    background-color: #ffffff;
    text-align: center;
    position: relative;
    overflow: hidden;
    transition: box-shadow 0.2s ease-out;
  }
  .img-container {
    width: 100%;
    height: 170px;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    clip-path: ellipse(120% 100% at 50% 0%);
  }
  .content {
    padding: 20px 10px 16px;
  }
  .title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 14px;
  }
  .categories {
    list-style-type: none;
    padding: 0;
    margin: 0;
    margin-bottom: 14px;
  }
  .category {
    margin-bottom: 8px;
  }
  .link {
    font-weight: 600;
    text-decoration: underline;
    margin-bottom: 10px;
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .category-group.clickable:hover {
    box-shadow: 0.5px 0.9px 37.2px 0.8px rgba(40, 39, 39, 0.32);
  }

  .category:last-child {
    margin-bottom: 32px;
  }

  .category a {
    color: inherit;
    cursor: pointer;
  }
</style>

<div class="category-group" class:clickable={!!link}>
  <MediaQuery query="(-webkit-min-device-pixel-ratio: 3), (min-resolution: 576dpi)" let:matches>
    {#if matches}
      <div class="img-container" style="background-image: url({resposiveImages.lg.url})" />
    {:else}
      <MediaQuery query="(-webkit-min-device-pixel-ratio: 2), (min-resolution: 384dpi)" let:matches>
        {#if matches}
          <div class="img-container" style="background-image: url({resposiveImages.md.url})" />
        {:else}
          <MediaQuery query="(-webkit-min-device-pixel-ratio: 1), (min-resolution: 192dpi)" let:matches>
            {#if matches}
              <div class="img-container" style="background-image: url({resposiveImages.sm.url})" />
            {:else}
              <div class="img-container" style="background-image: url({imgSrc})" />
            {/if}
          </MediaQuery>
        {/if}
      </MediaQuery>
    {/if}
  </MediaQuery>
  
  <div class="content">
    <div class="title">{title}</div>
    <ul class="categories">
      {#each categories as category}
        <li class="category">
          <a href={category.url}>
            {category.label}
          </a>
        </li>
      {/each}
    </ul>
    {#if link}
      <a class="link" href={link}>{$_('common.readMore')} »</a>
    {/if}
  </div>
</div>
