<script>
  import Button from '../Button.svelte';
  export let backgroundImage;
  export let buttonPlacement;
  export let contentOffset;
  export let heading;
  export let features;
  export let button;
  export let primary;
  export let style;

  const extendedStyle = `${style}; ${backgroundImage}`;
</script>

<div class="call-to-action-custom" class:hasImage={backgroundImage} style={extendedStyle}>
  <div class="img-dummy" />
  <div
    class="container"
    class:on-top={buttonPlacement === 'On top'}
    style={`margin-top: ${Number(contentOffset)}px`}
  >
    {#if Array.isArray(primary.heading) && primary.heading.some((h) => h.text)}
      <h6 class="heading">{heading[0].text}</h6>
    {/if}
    {#if features && features.length > 0}
      {#if features.some((f) => f.features)}
        <ul class="features">
          {#each features as featureData}
            {#if featureData}
              <li>{featureData.features}</li>
            {/if}
          {/each}
        </ul>
      {/if}
    {/if}
    {#if button.link && button.text}
      <a href={button.link}>
        <Button large>{button.text}</Button>
      </a>
    {/if}
  </div>
</div>

<style>
  h6.heading {
    margin: 0;
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .on-top {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
  .call-to-action-custom {
    background-color: var(--bgColor);
    text-align: center;
    color: #ffffff;
    position: relative;
    background-size: cover;
  }

  .hasImage .img-dummy {
    background-image: var(--bgImage);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 320px;
  }

  .heading {
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 29px;
  }
  .features {
    display: flex;
    margin-bottom: 42px;
    justify-content: center;
    font-size: 16px;
    font-weight: normal;
    flex-wrap: wrap;
    padding: 0;
    opacity: 0.9;
  }
  .features li {
    list-style-type: disc;
    margin: 0 22px;
    flex-wrap: wrap;
  }
  @media (max-width: 600px) {
    .heading {
      font-size: 24px;
    }
  }
</style>
