<script>
  import Siema from 'siema';
  import { LANGUAGE } from '../constants';
  import { onMount } from 'svelte';

  onMount(() => {
    const mySiema = new Siema({
      duration: 500,
      loop: true,
    });

    setInterval(() => mySiema.next(), 2000);
  });
</script>

<style>
  .wrapper {
    width: 100%;
    position: relative;
  }
  .frame {
    width: 100%;
  }
  .slides-viewport {
    position: absolute;
    z-index: 1;
    top: 6%;
    left: 12%;
    right: 12%;
    bottom: 12%;
    overflow: hidden;
  }
  .siema {
    height: 100%;
    width: 100%;
  }
  .slide {
    width: 100%;
    height: 100%;
  }
</style>

<!-- reference: http://bl.ocks.org/feo52/346b530cf7daf8369092 -->
<div class="wrapper">
  <img alt="" class="frame" src="/macbook-pro-retina.svg" />
  <div class="slides-viewport">
    <div class="siema">
      <img alt="" class="slide" src="screenshots/{LANGUAGE}/scheduler.jpg" />
      <img alt="" class="slide" src="screenshots/{LANGUAGE}/homepage.jpg" />
      <img alt="" class="slide" src="screenshots/{LANGUAGE}/booking.jpg" />
    </div>
  </div>
</div>
