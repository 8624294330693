<script>
  export let right = false;
</script>

<style>
  .ribbon {
    height: 34px;
    padding: 0 16px;
    position: absolute;
    right: -6px;
    top: 25px;
    text-align: center;
    border-top-right-radius: 3px;
    background: #0485c8;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ribbon:before {
    height: 0;
    width: 0;
    bottom: -5.5px;
    right: 0.1px;
    border-top: 6px solid #034466;
    border-right: 6px solid transparent;
  }
  @media only screen and (min-width: 1200px) {
    .ribbon:before,
    .ribbon:after {
      content: '';
      position: absolute;
    }
  }
  .ribbon:after {
    height: 0;
    width: 0;
    left: -16.5px;
    top: 0;
    border-top: 17px solid #0485c8;
    border-bottom: 17px solid #0485c8;
    border-left: 17px solid transparent;
  }
  @media only screen and (max-width: 70em) {
    .ribbon {
      height: 34px;
      padding: 0 16px;
      position: absolute;
      right: 0;
      left: 0px;
      top: 25px;
      text-align: center;
      border-top-right-radius: 3px;
      background: #0485c8;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
</style>

<div class="ribbon" class:right>
  <slot />
</div>
