<script>
  export let title;
  export let categories = [];
  export let imgSrc;
  export let link;

  import { _ } from 'svelte-i18n';
</script>

<style>
  .category-group {
    width: 256px;
    box-shadow: 0.5px 0.9px 37.2px 0.8px rgba(40, 39, 39, 0.16);
    background-color: #ffffff;
    text-align: center;
    position: relative;
    overflow: hidden;
    transition: box-shadow 0.2s ease-out;
  }
  .img-container {
    width: 100%;
    height: 170px;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    clip-path: ellipse(120% 100% at 50% 0%);
  }
  .content {
    padding: 20px 10px 16px;
  }
  .title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 14px;
  }
  .categories {
    list-style-type: none;
    padding: 0;
    margin: 0;
    margin-bottom: 14px;
  }
  .category {
    margin-bottom: 8px;
  }
  .link {
    font-weight: 600;
    text-decoration: underline;
    margin-bottom: 10px;
    display: block;
  }
  .link-stretch {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .category-group.clickable:hover {
    box-shadow: 0.5px 0.9px 37.2px 0.8px rgba(40, 39, 39, 0.32);
  }
</style>

<div class="category-group" class:clickable={!!link}>
  <div class="img-container" style="background-image: url({imgSrc})">
    <!-- <img class="img" src={imgSrc} alt={$_(title)} /> -->
  </div>
  <div class="content">
    <div class="title">{title}</div>
    <ul class="categories">
      {#each categories as category}
        <li class="category">{category}</li>
      {/each}
    </ul>
    {#if link}
      <a class="link" href={link}>{$_('common.readMore')} »</a>
    {/if}
  </div>
  {#if link}
    <a class="link-full" href={link}>
      <span class="link-stretch" />
    </a>
  {/if}
</div>
