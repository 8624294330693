<script>
  import { _ } from 'svelte-i18n';
  import PricingPlan from './PricingPlan.svelte';
  import { flatten } from 'lodash';

  export let primary = false;
  export let floating = false;
  export let label;
  export const currency = $_('pricingPlans.currency');
  export const period = $_('pricingPlans.period');
  export const features = [];
  export let type;
  export let licensesInformation;
  export let hideHeader = false;
  export let groupName;

  const informationKey = getInformatioKey(type);

  const supportedFeatures = Object.keys(licensesInformation).reduce((acc, curr, index) => {
    acc.push({ isGroupDivider: true, groupName: curr, groupIndex: index })
    acc.push(licensesInformation[curr].map(l => ({...l, groupName: curr, groupIndex: index})));
    acc = flatten(acc);
    return acc;
  }, [])
  .map(info => {  
    return {
      groupName: info.groupName,
      groupIndex: info.groupIndex,
      isGroupDivider: info.isGroupDivider,
      supported: info[informationKey],
      value: info[`${informationKey}Value`],
      information: info
    }
  });

  const title = type.Name;
  const price = type.Prices && type.Prices[0] ? type.Prices[0].Price : undefined;

  function getInformatioKey(type) {
    let keyPrefix = "";

    if(type.Id === 1) {
      keyPrefix = 'FreeEdition';
    } else if(type.Id === 2) {
      keyPrefix = 'StartEdition';
    } else if(type.Id === 3) {
      keyPrefix = 'SmartEdition';
    } else if(type.Id === 5) {
      keyPrefix = 'ProEdition';
    } else if(type.Id === 9) {
      keyPrefix = "EnterpriseEdition";
    }

    return keyPrefix;
  }

</script>

<PricingPlan
  {primary}
  {floating}
  {label}
  {price} 
  {title}
  {type}
  {hideHeader}
  features={supportedFeatures}
/>
