<script>
    export let feature;
</script>

{#if !feature.isGroupDivider}
  <div class={`feature ${feature.supported ? 'supported' : 'not-supported'} ${feature.value && feature.value !== 'NULL' && feature.value.includes('|') ? 'big' : ''} group-${feature.groupIndex}`}>
    <div class="feature__icons">
      {#if feature.supported && !feature.value && feature.value !== 'NULL'}
        <img
          src="/done.png"
          srcset="/done@2x.png 2x, /done@3x.png 3x"
          class="supported"
          alt="Feature supported"
        />
      {:else if !feature.value || feature.value === 'NULL'}
        <img
          src="/invalid-name.png"
          srcset="/invalid-name@2x.png 2x, /invalid-name@3x.png 3x"
          class="not-supported"
          alt="Feature not supported"
        />
      {/if}
      {#if feature.value && feature.value !== 'NULL' && !feature.value.includes('|')}
        <p>
          {feature.value}
        </p>
      {:else if feature.value && feature.value !== 'NULL' && feature.value.includes('|')}
        <div>
          {feature.value.split('|')[0]}
        </div>
        <small>
          {feature.value.split('|')[1]}
        </small>
      {/if}
    </div>
    <div class="feature__info">
        <strong>{feature.information.Name}</strong>
        <p>
          {feature.information.Description}
        </p>
      </div>
  </div>
{:else if feature.isGroupDivider && feature.groupIndex > 0 }
  <div class="feature__group-divider">{feature.groupName}</div>
{/if}



  <style>
    .feature {
    display: flex;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
    justify-content: center;
  }

  .feature__group-divider {
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f8fafc;
    box-shadow: 1px 0 0 0 rgba(76, 75, 76, 0.08);
  }
  .feature__info {
    display: none;
  }

  .feature.group-2:nth-of-type(even) {
    background-color: #fff;

  }
  .feature.group-2:nth-of-type(odd) {
    background-color: #f8fafc;
  }

  .feature__info {
    display: none;
    text-align: center;
  }
  .feature__icons {
    display: block;
    text-align: center;
  }

  img.supported,
  img.not-supported {
    width: 21px;
    height: auto;
  }

  @media only screen and (min-width: 60em) {
    .feature {
      height: 60px;
    }
    .feature.big {
      height: 100px;
    }
    .feature:nth-of-type(even) {
      background-color: #f8fafc;
    }
    .feature__group-divider {
      display: none;
    }
  }

  @media only screen and (max-width: 60em) {
    .feature {
      height: auto;
      padding: 12px 24px;
    }
    .feature__info {
      display: block;
    }
    .feature.supported, .feature.not-supported {
      
      display: flex;
      flex-direction: column;
    }
    .feature__icons {
      /* display: none; */
      /* border: 1px solid red; */
      order: 2;
    }
  }

  @media only screen and (min-width: 64em) {
    .feature {
      height: 60px;
    }
  }

  </style>